import React, { useState, useEffect, useRef } from 'react';
import { useUser } from './userContext';
import ChatLog from './ChatLog';
import ChatInput from './ChatInput';
import HintButtons from './HintButtons';
import AssistantCard from './AssistantCard';
import { useNavigate } from 'react-router-dom';
import { postChatMessage, fetchAssistants, fetchThreads, fetchChatLog, deleteThread as apiDeleteThread } from './apiService';
import '../css/App.css';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function MainLayout() {
    const chatLogRef = useRef(null);
    const [input, setInput] = useState("");
    const [currentAssistant, setCurrentAssistant] = useState("");
    const [currentAssistantName, setCurrentAssistantName] = useState(""); // State for the assistant's name
    const [chatLog, setChatLog] = useState([]);
    const { setUser, userId, setUserId, isAdmin, setUserName } = useUser();
    const [threads, setThreads] = useState([]);
    const [currentThread, setCurrentThread] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [sideMenuVisible, setSideMenuVisible] = useState(true);
    const navigate = useNavigate();
    const [hints, setHints] = useState([]);
    const [exploringAssistants, setExploringAssistants] = useState(true); // New state for controlling view

    useEffect(() => {
        if (chatLogRef.current) {
            const scrollElement = chatLogRef.current;
            scrollElement.scrollTop = scrollElement.scrollHeight;
        }
    }, [chatLog]);

    useEffect(() => {
        async function loadAssistants() {
            if (!userId) return;
            try {
                const data = await fetchAssistants(userId);
                setAssistants(data);
                if (data.length > 0) {
                    setCurrentAssistant(data[0].assistantId);
                    setCurrentAssistantName(data[0].name); // Set the initial assistant's name
                }
            } catch (error) {
                console.error('Failed to fetch assistants:', error);
            }
        }
        loadAssistants();
    }, [userId]);

    async function fetchHints(assistantId) {
        try {
            const response = await fetch(`/assistants/${assistantId}/hints`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Failed to fetch hints:', error);
            return [];
        }
    }

    useEffect(() => {
        async function loadHintsForAssistant() {
            if (currentAssistant) {
                const data = await fetchHints(currentAssistant);
                setHints(data.slice(0, 3)); // Limit to 3 hints
            }
        }
        loadHintsForAssistant();
    }, [currentAssistant]);

    useEffect(() => {
        const selectedAssistant = assistants.find(a => a.assistantId === currentAssistant);
        if (selectedAssistant) {
            setCurrentAssistantName(selectedAssistant.name);
        }
    }, [currentAssistant, assistants]);

    const newChat = () => {
        setCurrentThread(null);
        setChatLog([]);
        setExploringAssistants(true); // Set to true to explore assistants
    };

    useEffect(() => {
        async function loadThreads() {
            if (userId) {
                try {
                    const data = await fetchThreads(userId);
                    setThreads(data);
                } catch (error) {
                    console.error('Error fetching threads:', error);
                }
            }
        }
        loadThreads();
    }, [userId]);

    const deleteThread = async (threadId) => {
        try {
            await apiDeleteThread(threadId);
            const remainingThreads = threads.filter(t => t.id !== threadId);
            setThreads(remainingThreads);
    
            if (currentThread && currentThread.id === threadId) {
                setCurrentThread(null);
                setChatLog([]);
            }
    
            if (remainingThreads.length === 0) {
                setCurrentThread(null);
                setChatLog([]);
            }
        } catch (error) {
            console.error('Failed to delete thread:', error);
        }
    };    

    const selectThread = async (thread) => {
        setCurrentThread(thread);
        try {
            const { assistantId, messages } = await fetchChatLog(thread.id);
            setChatLog(messages.slice(-10));

            // Update currentAssistant based on the selected thread
            setCurrentAssistant(assistantId);

            // Find and set the current assistant's name
            const selectedAssistant = assistants.find(a => a.assistantId === assistantId);
            if (selectedAssistant) {
                setCurrentAssistantName(selectedAssistant.name);
            }

            // Exit explore assistants view if active
            setExploringAssistants(false);
        } catch (error) {
            console.error('Error fetching chat log for thread:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        setUser(null);
        setUserId(null);
        setUserName(null);
    };

    const toggleSideMenu = () => {
        setSideMenuVisible(!sideMenuVisible);
    };

    function ThreadMenu({ threadId, onDelete }) {
        const [anchorEl, setAnchorEl] = useState(null);
        const open = Boolean(anchorEl);

        const handleClick = (event) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const handleDelete = () => {
            onDelete(threadId);
            handleClose();
        };

        return (
            <>
                <IconButton aria-label="settings" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
            </>
        );
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (!input.trim()) return;

        setIsLoading(true);
        const userMessage = { user: "you", message: input };
        setChatLog(chatLog => [...chatLog, userMessage]);
        setInput("");

        try {
            const threadToSend = currentThread ? currentThread : null;
            const response = await postChatMessage(input, currentAssistant, userId, threadToSend);
            const assistantMessage = { user: "assistant", message: response.message };
            setChatLog(chatLog => [...chatLog, assistantMessage]);

            if (response.thread && (!currentThread || response.thread.id !== currentThread.id)) {
                setCurrentThread(response.thread);
                setThreads(threads => [...threads, response.thread]);
            }
        } catch (error) {
            console.error("Error sending message:", error);
            setChatLog(chatLog => [...chatLog, { user: "assistant", message: "Failed to fetch response." }]);
        } finally {
            setIsLoading(false);
        }
    }

    const handleAssistantCardClick = (assistant) => {
        setCurrentAssistant(assistant.assistantId);
        setCurrentAssistantName(assistant.name);
        setExploringAssistants(false);
        setChatLog([]); // Clear chat log when starting a new chat
        setCurrentThread(null); // Clear current thread when starting a new chat
    };

    return (
        <div className="App">
            {sideMenuVisible && (
            <aside className="sidemenu">
                <div className="side-menu-button" onClick={newChat}>
                    <span>New Chat</span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        className="icon-md">
                        <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M12 5a1 1 0 0 1 1 1v5h5a1 1 0 1 1 0 2h-5v5a1 1 0 1 1-2 0v-5H6a1 1 0 1 1 0-2h5V6a1 1 0 0 1 1-1Z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <p></p>
                <div className="conversation-section">
                <div className='conversations'>
                <span>Conversations</span>
                </div>
                <br></br>
                {threads.map(thread => (
                    <div key={thread.id} className="thread-entry" onClick={() => selectThread(thread)}>
                        <div className="thread-text">{thread.title || "New chat created"}</div>
                        <ThreadMenu threadId={thread.id} onDelete={deleteThread} />
                    </div>
                ))}
                </div>
                <div className="bottom-container">
                    <button onClick={handleLogout} className="logout-button">Logout</button>
                    {isAdmin && (
                        <button onClick={() => navigate('/admin')} className="admin-button">Admin</button>
                    )}
                </div>
            </aside>
            )}
            <section className={`chat-box ${sideMenuVisible ? '' : 'full-width'}`}>
                <div className='chat-header'>
                    <div className="assistants-menu">
                      <button onClick={toggleSideMenu} className="toggle-side-menu-button">
                        {sideMenuVisible ? (
                              <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="none"
                              viewBox="0 0 16 16" >
                              <g fill="#fff">
                                <path d="M11.726 5.263a.7.7 0 1 0-.952-1.026l-3.5 3.25a.7.7 0 0 0 0 1.026l3.5 3.25a.7.7 0 0 0 .952-1.026L8.78 8l2.947-2.737z" />
                                <path
                                  fillRule="evenodd"
                                  d="M1 3.25A2.25 2.25 0 0 1 3.25 1h9.5A2.25 2.25 0 0 1 15 3.25v9.5A2.25 2.25 0 0 1 12.75 15h-9.5A2.25 2.25 0 0 1 1 12.75v-9.5zm2.25-.75a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h1.3v-11h-1.3zm9.5 11h-6.8v-11h6.8a.75.75 0 0 1 .75.75v9.5a.75.75 0 0 1-.75.75z"
                                  clipRule="evenodd"
                                />
                              </g>
                            </svg>
                        ) : (<svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                fill="none"
                                viewBox="0 0 16 16">
                                <g fill="#fff">
                                  <path d="M7.774 5.263a.7.7 0 1 1 .952-1.026l3.5 3.25a.7.7 0 0 1 0 1.026l-3.5 3.25a.7.7 0 0 1-.952-1.026L10.72 8 7.774 5.263z" />
                                  <path
                                    fillRule="evenodd"
                                    d="M1 3.25A2.25 2.25 0 0 1 3.25 1h9.5A2.25 2.25 0 0 1 15 3.25v9.5A2.25 2.25 0 0 1 12.75 15h-9.5A2.25 2.25 0 0 1 1 12.75v-9.5zm2.25-.75a.75.75 0 0 0-.75.75v9.5c0 .414.336.75.75.75h1.3v-11h-1.3zm9.5 11h-6.8v-11h6.8a.75.75 0 0 1 .75.75v9.5a.75.75 0 0 1-.75.75z"
                                    clipRule="evenodd"
                                  />
                                </g>
                              </svg>
                            )}
                      </button>
                      {!exploringAssistants && <h1>{currentAssistantName}</h1>}
                    </div>
                </div>
                <div className={`chat-log-container ${chatLog.length === 0 ? 'center' : ''}`}>
                    {exploringAssistants ? (
                    <div className="assistant-cards-wrapper">
                            <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Capa_1"
    width={88}
    height={70}
    style={{
    enableBackground: "new 0 0 800 500",
    }}
    viewBox="0 0 800 500">
    <style>{".st0{fill:#1c006a}"}</style>
    <path d="M542.1 298.4c0-5.5-1.5-9.5-4.6-12.1s-7.3-3.9-12.6-3.9c-3.5 0-7.1.4-10.5 1.3-3.2.8-6.2 2.1-8.9 4v92c-2.7.7-5.5 1.3-8.4 1.6-3.9.5-7.9.8-11.8.8-3.5 0-7-.2-10.5-.8-2.8-.4-5.5-1.5-7.8-3.2-2.2-1.7-3.9-3.9-4.9-6.5-1.3-3.4-1.9-7.1-1.7-10.8v-79.5c0-4.8 1-8.7 3-11.7 2.3-3.2 5.1-6 8.4-8.2 6-4.2 13.6-7.7 22.7-10.4 9.9-2.8 20.1-4.1 30.4-4 20 0 35.4 4.4 46.2 13.1 10.8 8.8 16.2 20.9 16.2 36.5v83c-2.7.7-5.5 1.3-8.3 1.6-3.9.5-7.9.8-11.8.8-3.5 0-7-.2-10.5-.8-2.8-.4-5.5-1.5-7.8-3.2-2.2-1.7-3.9-3.9-4.9-6.5-1.3-3.4-1.9-7.1-1.7-10.7v-62.4zM368.6 247.2h.1c8.1 0 16.2 1.5 23.8 4.4 7.4 2.9 14.2 7.3 19.9 12.9 5.9 6 10.6 13.1 13.6 21 3.3 8.4 5 18 5 29 0 11.3-1.7 21.2-5.1 29.6-3.1 8-8 15.2-14.2 21-6.4 5.8-14.1 10.2-22.4 12.7-9.4 3-19.2 4.4-29.1 4.3-11.5 0-21.2-1.4-29.2-4-7.2-2.3-14.1-5.7-20.4-10-7.7-5.5-11.5-12.5-11.5-21V182.4c2.7-.7 5.5-1.3 8.4-1.7 3.9-.6 7.8-.9 11.8-.9 3.5 0 6.9.2 10.3.8 2.8.4 5.4 1.5 7.7 3.1 2.1 1.6 3.8 3.9 4.7 6.4 1.2 3.4 1.7 7 1.6 10.6V252l-.3 34.2v50.6c0 4.3 2.8 8.1 6.8 9.3h.1c3.3.9 6.6 1.2 10 1.2 8 0 14.2-2.7 18.7-8.2 4.4-5.5 6.7-13.6 6.7-24.4 0-11-2.3-19.1-6.8-24.5-4.5-5.4-7.6-7.3-15.9-11.3-2-1-10.6-6-9.3-17.2.8-8.8 7.7-14.4 15-14.5zM686.4 247.2h.2c8.1 0 16.2 1.5 23.8 4.4 7.4 2.9 14.2 7.3 19.8 12.9 6 6 10.6 13.1 13.6 21 3.3 8.4 5 18 5 29 0 11.3-1.7 21.2-5.1 29.6-3.1 8-8 15.2-14.2 21-6.5 5.8-14.1 10.2-22.4 12.7-9.4 3-19.2 4.4-29.1 4.3-11.5 0-21.2-1.4-29.2-4-7.2-2.3-14.1-5.7-20.4-10-7.7-5.4-11.5-12.4-11.5-20.9v-165c2.8-.7 5.5-1.3 8.4-1.7 3.9-.6 7.8-.9 11.8-.9 3.5 0 6.9.2 10.3.8 2.8.4 5.4 1.5 7.7 3.1 2.1 1.6 3.8 3.9 4.7 6.4 1.2 3.4 1.7 7 1.6 10.6V252l-.2 34.2v50.6c0 4.3 2.8 8.1 6.8 9.3h.1c3.3.9 6.6 1.2 10 1.2 8 0 14.2-2.7 18.7-8.2 4.4-5.5 6.7-13.6 6.7-24.4 0-11-2.3-19.1-6.8-24.5-4.5-5.4-7.6-7.3-16-11.3-2-1-10.6-6-9.3-17.2.8-8.8 7.7-14.4 15-14.5z"
    className="st0"/>
    <path d="M247.4 145.7c0-22.6-18.3-41-41-41-18.2 0-34.3 12-39.4 29.5-23.6-14.2-50.5-21.7-78-21.8l-20.7-.1c-.8 2.5-1.4 5.1-1.8 7.7-.6 3.9-1 7.8-1 11.8 0 3.5.2 6.9.7 10.4v.1c1 6.3 5.4 10.9 15.7 11.1h.1c7.2.2 17.7.2 26.2 1.8 26.5 4.7 50.2 19 66.7 40.2 11.2 14.1 18.6 30.8 21.7 48.6 1.5 8.5 1.4 18.9 1.6 26v.2c0 10.1 4.7 14.5 11 15.6h.1c3.5.5 7 .8 10.5.8 4 0 8-.3 11.9-.9 2.6-.4 5.2-1 7.8-1.7l.1-20.5c.2-27.6-7.4-54.8-21.8-78.4 17.4-5.3 29.5-21.2 29.6-39.4z"
    style={{
        fill: "#00ffbc", }}/>
    <path d="M238.9 352.4c-1-6.3-5.4-11.1-15.7-11.2h-.1c-7.2-.2-17.6-.2-26.2-1.8-26.5-4.7-50.2-19-66.7-40.2-11.2-14.1-18.6-30.8-21.7-48.6-1.5-8.5-1.4-18.9-1.6-26v-.1c0-10.1-4.7-14.6-11-15.6h-.1c-3.5-.5-7-.8-10.5-.8-4 0-8 .3-11.9.9-2.6.4-5.2 1-7.8 1.7l-.1 20.5c-.2 27.6 7.4 54.8 21.8 78.4-21.7 6.3-34.3 29-28 50.8s29 34.3 50.8 28c13.5-3.9 24.1-14.5 28-28 23.6 14.2 50.5 21.7 78 21.8l20.7.1c.8-2.5 1.4-5.1 1.8-7.7.6-3.9 1-7.8 1-11.8.1-3.5-.2-7-.7-10.4z"
    className="st0" />
</svg>
                    <h2 className="assistant-cards-title">Choose an Assistant</h2> {/* Add this header */}
                        <div className="assistant-cards">
                            {assistants.map(assistant => (
                                <AssistantCard
                                    key={assistant.assistantId}
                                    assistant={assistant}
                                    onClick={handleAssistantCardClick}
                                />
                            ))}
                        </div>
                        </div>
                    ) : (
                        chatLog.length === 0 ? (
                            <HintButtons setInput={setInput} handleSubmit={handleSubmit} hints={hints} />
                        ) : (
                            <ChatLog chatLog={chatLog} ref={chatLogRef} />
                        )
                    )}
                </div>
                {!exploringAssistants && (
                    <ChatInput sideMenuVisible={sideMenuVisible} input={input} setInput={setInput} handleSubmit={handleSubmit} isLoading={isLoading} />
                )}
            </section>
        </div>
    );
}

export default MainLayout;