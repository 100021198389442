import React, { useState, useEffect } from 'react';
import { useUser } from './userContext';

function CreateAssistantForm({ onSave, onCancel }) {
    const { userId, userName } = useUser();
    const [name, setName] = useState('');
    const [instructions, setInstructions] = useState('');
    const [model, setModel] = useState('');
    const [knowledgeBase, setKnowledgeBase] = useState('');
    const [knowledgeBaseName, setKnowledgeBaseName] = useState('');
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [hints, setHints] = useState([{ hint: '', prompt: '' }]);

    useEffect(() => {
        const fetchKnowledgeBases = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/vector-stores?userId=${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch knowledge bases');
                }
                const data = await response.json();
                setKnowledgeBases(data); // Assuming data is an array of knowledge bases
            } catch (error) {
                console.error("Error fetching knowledge bases:", error);
            }
        };

        fetchKnowledgeBases();
    }, []);


    const handleKnowledgeBaseChange = (event) => {
        const selectedId = event.target.value;
        const selectedKb = knowledgeBases.find(kb => kb.vectorStoreId === selectedId);
        setKnowledgeBase(selectedId);
        setKnowledgeBaseName(selectedKb ? selectedKb.name : '');
    };

    const handleHintChange = (index, field, value) => {
        const newHints = [...hints];
        newHints[index][field] = value;
        setHints(newHints);
    };

    const addHint = () => {
        setHints([...hints, { hint: '', prompt: '' }]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const assistantData = { 
            name, 
            instructions, 
            model, 
            vector_store_id: knowledgeBase, 
            vector_store_name: knowledgeBaseName,
            created_by: userName, // Include user information
            user_id: userId, // Include user ID
            hints: hints.filter(hint => hint.hint && hint.prompt),
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/create-assistant`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(assistantData),
            });

            if (!response.ok) {
                throw new Error('Failed to create assistant');
            }

            const data = await response.json();
            console.log("Assistant created successfully:", data);
            onSave(data); // Propagate up any state changes or redirections
        } catch (error) {
            console.error("Failed to submit assistant creation:", error);
        }
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Create a new assistant</h2>
                <form onSubmit={handleSubmit} className="create-assistant-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            placeholder="Enter a user friendly name" required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="instructions">Instructions</label>
                        <textarea
                            id="instructions"
                            value={instructions}
                            onChange={(e) => setInstructions(e.target.value)}
                            className="form-control"
                            placeholder="You are a helpful assistant." required
                        />
                    </div>
                    <div className="form-group-inline">
                    <div className="form-group">
                        <label htmlFor="model">Model</label>
                        <select
                            id="model"
                            value={model}
                            onChange={(e) => setModel(e.target.value)}
                            className="form-control"
                        required>
                            <option value="">Select a model</option>
                            <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                            <option value="gpt-4-turbo-preview">GPT-4</option>
                            <option value="gpt-4o">GPT-4o</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="knowledgeBase">Knowledge Base</label>
                        <select
                            id="knowledgeBase"
                            value={knowledgeBase}
                            onChange={handleKnowledgeBaseChange}
                            className="form-control"
                        required>
                            <option value="">Select a knowledge base</option>
                            {knowledgeBases.map(kb => (
                                <option key={kb.vectorStoreId} value={kb.vectorStoreId}>
                                    {kb.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    </div>
                    <div className="form-group">
                        <label>Hints (Optional - Create hints to guide users queries)</label>
                        {hints.map((hint, index) => (
                            <div key={index} className="form-group-inline">
                                <input
                                    type="text"
                                    value={hint.hint}
                                    onChange={(e) => handleHintChange(index, 'hint', e.target.value)}
                                    className="form-control"
                                    placeholder={`Hint ${index + 1}`}
                                    required
                                />
                                <input
                                    type="text"
                                    value={hint.prompt}
                                    onChange={(e) => handleHintChange(index, 'prompt', e.target.value)}
                                    className="form-control"
                                    placeholder={`Prompt ${index + 1} - Provide a detailed prompt for Hint ${index + 1}`}
                                    required
                                />
                            </div>
                        ))}
                        {hints.length < 3 && (
                            <button type="button" onClick={addHint} className="btn btn-primary">Add Hint</button>
                        )}
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="btn btn-primary">Create</button>
                        <button type="button" onClick={onCancel} className="btn btn-secondary">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateAssistantForm;