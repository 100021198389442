import React, { useState, useEffect } from 'react';
import { useUser } from './userContext'; // Adjust the import path as necessary
import Select from 'react-select';

function CreateSecurityGroupForm({ onSave, onCancel }) {
    const { userId, userName } = useUser(); // Get user information from context
    const [name, setName] = useState('');
    const [domains, setDomains] = useState('');
    const [specificUsers, setSpecificUsers] = useState([]);
    const [specificAssistants, setSpecificAssistants] = useState([]); // New state for selected assistants
    const [users, setUsers] = useState([]);
    const [assistants, setAssistants] = useState([]);
    const [vectorStores, setVectorStores] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/users`);
                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }
                const data = await response.json();
                setUsers(data); // Assuming data is an array of users
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };

        const fetchAssistants = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/assistants?userId=${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch assistants');
                }
                const data = await response.json();
                setAssistants(data); // Assuming data is an array of assistants
            } catch (error) {
                console.error("Error fetching assistants:", error);
            }
        };

        const fetchVectorStores = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/vector-stores?userId=${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch vector stores');
                }
                const data = await response.json();
                setVectorStores(data); // Assuming data is an array of vector stores
            } catch (error) {
                console.error("Error fetching vector stores:", error);
            }
        };

        fetchUsers();
        fetchAssistants();
        fetchVectorStores();
    }, [userId]);

    const handleSpecificUsersChange = (selectedOptions) => {
        setSpecificUsers(selectedOptions);
    };

    const handleSpecificAssistantsChange = (selectedOptions) => {
        setSpecificAssistants(selectedOptions);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const securityGroupData = {
            name,
            domains: domains.split(',').map(domain => domain.trim()),
            users: specificUsers.map(user => user.value),
            assistants: specificAssistants.map(assistant => assistant.value), // Collect selected assistants
            vectorStores: vectorStores.map(store => store.vectorStoreId),
            created_by: userName, // Include user information
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/securitygroups`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(securityGroupData),
            });

            if (!response.ok) {
                throw new Error('Failed to create security group');
            }

            const data = await response.json();
            console.log("Security group created successfully:", data);
            onSave(data); // Propagate up any state changes or redirections
        } catch (error) {
            console.error("Failed to submit security group creation:", error);
        }
    };

    const userOptions = users.map(user => ({
        value: user._id,
        label: `${user.name} (${user.email})`
    }));

    const assistantOptions = assistants.map(assistant => ({
        value: assistant._id,
        label: assistant.name
    }));

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Create a new security group</h2>
                <form onSubmit={handleSubmit} className="create-security-group-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            placeholder="Enter descriptive group name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="domains"> Domains (Give access to specific domains)</label>
                        <input
                            id="domains"
                            type="text"
                            value={domains}
                            onChange={(e) => setDomains(e.target.value)}
                            className="form-control"
                            placeholder="Enter domains, separated by commas"
                        />
                    </div>
                    <br></br>
                    <div className='form-group'>
                        <label htmlFor="specificUsers">Users (Assign specific users)</label>
                        <Select
                            id="specificUsers"
                            isMulti
                            value={specificUsers}
                            onChange={handleSpecificUsersChange}
                            options={userOptions}
                            className="form-control-specific"
                            placeholder='Select specific users'
                        />
                    </div>
                    <br></br>
                    <div className='form-group'>
                        <label htmlFor="specificAssistants">Assistants (Assign assistants)</label>
                        <Select
                            id="specificAssistants"
                            isMulti
                            value={specificAssistants}
                            onChange={handleSpecificAssistantsChange}
                            options={assistantOptions}
                            className="form-control-specific"
                            placeholder='Select specific assistants'
                        />
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="btn btn-primary">Create</button>
                        <button type="button" onClick={onCancel} className="btn btn-secondary">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateSecurityGroupForm;