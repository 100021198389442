const BASE_URL = `${process.env.REACT_APP_CLIENT_HOME_URL}/answer`;

export async function postChatMessage(message, currentAssistant, userId, thread) {
    try {
        const response = await fetch(`${BASE_URL}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                message: message,
                currentAssistant: currentAssistant,
                userId: userId,
                currentThread: thread,
            })
        });
        if (!response.ok) throw new Error('Network response was not ok');
        return await response.json();
    } catch (error) {
        console.error("Error fetching data: ", error);
        throw error; // re-throwing the error to be handled by the caller
    }
}


export async function fetchAssistants(userId) {
    const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/assistants?userId=${userId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch assistants');
    }
    return await response.json();
}

export async function fetchThreads(userId) {
    const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/threads?userId=${userId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch threads');
    }
    return await response.json();
}

export async function fetchChatLog(threadId) {
    const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/chat-log/${threadId}`);
    if (!response.ok) {
        throw new Error('Failed to fetch chat log');
    }
    return await response.json();
}

export async function deleteThread(threadId) {
    const response = await fetch(`/threads/${threadId}`, {
        method: 'DELETE'
    });
    if (!response.ok) {
        throw new Error('Failed to delete thread');
    }
    return response.json();
}

export default {postChatMessage, fetchAssistants, fetchThreads, fetchChatLog, deleteThread};