import React from 'react';

const AssistantCard = ({ assistant, onClick }) => {
    return (
        <div className="assistant-card" onClick={() => onClick(assistant)}>
            <span>{assistant.name}</span>
            <p>{assistant.description}</p>
        </div>
    );
};

export default AssistantCard;