import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VectorStores from './VectorStores.js';
import Assistants from './Assistants';
import SecurityGroups from './SecurityGroups';
import CreateAssistantForm from './CreateAssistantForm';
import CreateVectorStoreForm from './CreateVectorStoreForm';
import UserActivity from './UserActivity.js';
import CreateSecurityGroupForm from './CreateSecurityGroupForm'; // Import the new form


const AdminPanel = () => {
    const [activeTab, setActiveTab] = useState('activity'); // Default tab
    const [showForm, setShowForm] = useState(false); // State to control the visibility of the assistant form
    const [showDropdown, setShowDropdown] = useState(false);
    const [formType, setFormType] = useState('');
    const [sideMenuVisible, setSideMenuVisible] = useState(true); // State for side menu visibility
    const navigate = useNavigate();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setShowDropdown(false); // Hide dropdown when an item is clicked
        if (tab !== 'assistants') {
            setShowForm(false); // Hide form when navigating away from 'assistants'
        }
    };

    const showCreateAssistant = () => {
        setFormType('assistant');
        setShowForm(true);
        setShowDropdown(false);
    };

    const showCreateVectorStore = () => {
        setFormType('vectorstore');
        setShowForm(true);
        setShowDropdown(false);
    };

    const showCreateSecurityGroup = () => {
        setFormType('securitygroup');
        setShowForm(true);
        setShowDropdown(false);
    };
    
    // Function to navigate back to the chat
    const goToChat = () => {
        navigate('/');
    };

    const handleCreateNewAssistant = (assistantData) => {
        console.log("New Assistant Data:", assistantData);
        setShowForm(false); // Hide form after creating an assistant
    };

    const handleCreateVectorStore = (vectorStoreData) => {
        console.log("New Vector Store Data:", vectorStoreData);
        setShowForm(false); // Hide form after creating vector store
    };

    const handleCreateSecurityGroup = (groupData) => {
        console.log("New Security Group Data:", groupData);
        setShowForm(false); // Hide form after creating vector store
    };

    return (
        <div className='App'>
            {sideMenuVisible && (
            <aside className="admin-sidebar">
                <div className="side-menu-button" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                    <span>Create</span>
                    {showDropdown && (
                        <div className="dropdown-menu">
                            <div onClick={showCreateAssistant}>New Assistant</div>
                            <div onClick={showCreateVectorStore}>New Knowledge Base</div>
                            <div onClick={showCreateSecurityGroup}>New Security Group</div>
                        </div>
                    )}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={18}
                        fill="currentColor"
                        viewBox="0 0 24 24">
                        <path
                            fillRule="evenodd"
                            d="M12 5a1 1 0 0 1 1 1v5h5a1 1 0 1 1 0 2h-5v5a1 1 0 1 1-2 0v-5H6a1 1 0 1 1 0-2h5V6a1 1 0 0 1 1-1Z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <ul>
                    <li className={activeTab === 'activity' ? 'active' : ''} onClick={() => handleTabChange('activity')}>Activity</li>
                    <li className={activeTab === 'vectorstore' ? 'active' : ''} onClick={() => handleTabChange('vectorstore')}>Knowledge Bases</li>
                    <li className={activeTab === 'assistants' ? 'active' : ''} onClick={() => handleTabChange('assistants')}>Assistants</li>
                    <li className={activeTab === 'securitygroups' ? 'active' : ''} onClick={() => handleTabChange('securitygroups')}>Security Groups</li>

                </ul>
            <div className="bottom-container">
                <button onClick={goToChat} className="admin-button">Back to chat</button>
            </div>
            </aside>
            )}
            <div className={`admin-content ${sideMenuVisible ? '' : 'full-width'}`}>
                {activeTab === 'vectorstore' && <VectorStores />}
                {activeTab === 'assistants' && <Assistants />}
                {activeTab === 'activity' && <UserActivity />}
                {activeTab === 'securitygroups' && <SecurityGroups />}

                {/* Conditional rendering based on formType */}
                {showForm && formType === 'assistant' && (
                    <CreateAssistantForm
                        onSave={handleCreateNewAssistant}
                        onCancel={() => setShowForm(false)}
                    />
                )}
                {/* Possibly render a form for vector stores if needed */}
                {showForm && formType === 'vectorstore' && (
                    <CreateVectorStoreForm
                        onSave={handleCreateVectorStore}
                        onCancel={() => setShowForm(false)}
                    />
                )}
                {showForm && formType === 'securitygroup' && (
                    <CreateSecurityGroupForm
                        onSave={handleCreateSecurityGroup}
                        onCancel={() => setShowForm(false)}
                        />
                )}
            
            </div>

        </div>
    );
};

export default AdminPanel;