import React from 'react';

const HintButtons = ({ setInput, hints }) => {
  const handleHintClick = (hint) => {
    setInput(hint.prompt);

    // Simulate a click on the send button after a short delay
    setTimeout(() => {
      const sendButton = document.getElementById('send-button');
      if (sendButton) {
        sendButton.click();
      }
    }, 0);
  };

  return (
    <div className="hint-buttons">
      {hints.map((hint, index) => (
        <button key={index} onClick={() => handleHintClick(hint)}>
          {hint.hint}
        </button>
      ))}
    </div>
  );
};

export default HintButtons;