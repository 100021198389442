import React, { useEffect, useRef, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import '../css/App.css';
import { useUser } from '../components/userContext';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AdminPanel from '../components/AdminPanel';
import MainLayout from '../components/MainLayout';

function App() {
    const { user, setUser, setUserId, isAdmin, setUserAdminStatus, setUserName, setUserSuperAdminStatus } = useUser();
    const navigate = useNavigate();
    const authChecked = useRef(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (!authChecked.current) {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token') || localStorage.getItem('userToken');

            if (token) {
                // console.log("Token found, setting user state:", token);
                const decoded = jwtDecode(token);
                setUser({ loggedIn: true, ...decoded });
                setUserId(decoded.userId);
                setUserName(decoded.name);
                setUserAdminStatus(decoded.isAdmin);
                setUserSuperAdminStatus(decoded.isSuperAdmin);
                localStorage.setItem('userToken', token);  // Store token for future sessions
            } else if (!user) {
                console.log("No token found, need to login.");
            }

            authChecked.current = true; // Mark that auth has been checked
        }
    }, [navigate, setUser, setUserId, setUserAdminStatus, setUserSuperAdminStatus, user, setUserName]);

    const handleGoogleLogin = () => {
        console.log("Redirecting to Google OAuth login.");
        const clientId = '563104687257-56ml4uf8htddcj8k4kidurkpm0dbapfj.apps.googleusercontent.com';
        const redirectUri = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
        const scope = encodeURIComponent('openid profile email');
        const responseType = 'code';
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${responseType}&access_type=offline&prompt=consent`;
        window.location.href = authUrl;
    };

    const handleEmailLogin = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/auth/send-magic-link`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
            if (response.ok) {
                setMessage('Access link sent! Check your email to log in.');
            } else {
                setMessage('Failed to send access link. Please try again.');
            }
        } catch (error) {
            console.error('Error sending magic link:', error);
            setMessage('An error occurred. Please try again.');
        }
    };

    if (!user) {
        return (
            <div className="AppLogin">
                <div className="login-modal">
                    <h2>Welcome to BNB's Assistant Hub</h2>
                    <p>Please log in with your Google account or request an access link to continue.</p>
                    <div className="login-container">
                        <div className="login-box">
                            <h2>BNB Users</h2>
                            <button onClick={handleGoogleLogin} className="login-button">Login with Google</button>
                        </div>
                        <div className="login-box">
                            <h2>External Users</h2>
                            <div className="email-login">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Please enter your email"
                                    className="email-input"
                                />
                                <button onClick={handleEmailLogin} className="login-button"> Request Access Link</button>
                            </div>
                            {message && <p className="login-message">{message}</p>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <Routes>
            <Route path="/" element={<MainLayout />} />
            {isAdmin && <Route path="/admin" element={<AdminPanel />} />}
        </Routes>
    );
}

export default App;