import React, { useState, useRef } from 'react';
import { useUser } from './userContext'; // Adjust the import path as necessary

function CreateVectorStoreForm({ onSave, onCancel }) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);  // New state for loading
    const {userName} = useUser();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);  // Set loading to true

        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('created_by', userName);
        files.forEach(file => formData.append('files', file));

        try {
            const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/create-vector-store`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to create vector store');
            }
            const data = await response.json();
            console.log("Vector store created successfully:", data);
            onSave(data);
        } catch (error) {
            console.error("Failed to submit vector store creation:", error);
        }
    };

    const handleFileChange = (event) => {
        setFiles([...files, ...Array.from(event.target.files)]);
        event.target.value = ''; // Reset the file input
    };

    const handleRemoveFile = (fileToRemove) => {
        setFiles(files.filter(file => file !== fileToRemove));
    };

    const handleRemoveAllFiles = () => {
        setFiles([]);
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <h2>Create a new knowledge base</h2>
                <form onSubmit={handleSubmit} className="create-vector-store-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            placeholder="Enter knowledge base name"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <textarea
                            id="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="form-control"
                            placeholder="Describe the contents and purpose of the knowledge base"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="files">Upload files</label>
                        <input
                            type="file"
                            id="files"
                            multiple
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                        />
                        <div className="custom-file-input">
                            <button type="button" className="btn btn-primary" onClick={() => fileInputRef.current.click()}>Choose files</button>
                            <span>{files.length > 0 ? `${files.length} file(s) selected` : 'No file chosen'}</span>
                        </div>
                        {files.length > 0 && (
                            <div className="file-list">
                                <h3>Selected files</h3>
                                <ul>
                                    {files.map((file, index) => (
                                        <li key={index}>
                                            {file.name}
                                            <button type="button" onClick={() => handleRemoveFile(file)} className='remove-btn'>x</button>
                                        <p></p>
                                        </li>
                                    ))}
                                </ul>
                                <button type="button" onClick={handleRemoveAllFiles} className="btn btn-primary">Remove all files</button>
                            </div>
                        )}
                    </div>
                    <div className="form-actions">
                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                            {isLoading ? <span className="spinner"></span> : 'Create'}
                        </button>
                        <button type="button" onClick={onCancel} className="btn btn-secondary" disabled={isLoading}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateVectorStoreForm;