import React from 'react';
import ChatMessage from './ChatMessage';

const ChatLog = React.forwardRef(({ chatLog }, ref) => {
    if (!Array.isArray(chatLog)) {
        console.error('Expected chatLog to be an array, but received:', chatLog);
        return null;
    }

    return (
        <div className="chat-log-container" ref={ref}>
            {chatLog.map((message, index) => (
                <ChatMessage key={index} message={message} />
            ))}
        </div>
    );
});


export default ChatLog;