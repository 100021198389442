import React, { useEffect, useState } from 'react';

const UserActivity = () => {
  const [messageCounts, setMessageCounts] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'count', direction: 'desc' });

  useEffect(() => {
    const fetchMessageCounts = async () => {
      try {
        const response = await fetch('/user-message-counts');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setMessageCounts(data);
      } catch (error) {
        console.error('Failed to fetch message counts:', error);
      }
    };

    fetchMessageCounts();
  }, []);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedMessageCounts = [...messageCounts].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  return (
    <div>
      <h1 className='admin-title'>User Activity</h1>
      <table className="user-activity-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('email')}>User {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
            <th onClick={() => handleSort('domain')}>Domain {sortConfig.key === 'domain' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
            <th onClick={() => handleSort('count')} className='right-align'>Messages {sortConfig.key === 'count' && (sortConfig.direction === 'asc' ? '▲' : '▼')}</th>
          
          </tr>
        </thead>
        <tbody>
          {sortedMessageCounts.map((user) => (
            <tr key={user._id}>
              <td>{user.email}</td>
              <td>{user.domain}</td>
              <td className="right-align">{user.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserActivity;