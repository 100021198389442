import React, { useState, useEffect, useRef } from 'react';
import { useUser } from './userContext';

const VectorStores = () => {
    const [vectorStores, setVectorStores] = useState([]);
    const [selectedVectorStoreId, setSelectedVectorStoreId] = useState('');
    const { userId } = useUser();
    const [files, setFiles] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editValues, setEditValues] = useState({});
    const [filesToUpload, setFilesToUpload] = useState([]);
    const fileInputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchVectorStores() {
            if (!userId) return;
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/vector-stores?userId=${userId}`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    setVectorStores(data);
                    if (data.length > 0) {
                        setSelectedVectorStoreId(data[0].vectorStoreId);
                    }
                } else {
                    console.error('Expected an array of vectors but got:', data);
                }
            } catch (error) {
                console.error('Failed to fetch vector stores:', error);
            }
        }

        fetchVectorStores();
    }, [userId]);

    useEffect(() => {
        const fetchFiles = async () => {
            if (selectedVectorStoreId) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/files/${selectedVectorStoreId}`);
                    const data = await response.json();
                    setFiles(data);
                } catch (error) {
                    console.error('Error fetching files:', error);
                }
            }
        };
        fetchFiles();
    }, [selectedVectorStoreId]);

    const selectedVectorStore = vectorStores.find(store => store.vectorStoreId === selectedVectorStoreId);

    const handleSelectionChange = (event) => {
        setSelectedVectorStoreId(event.target.value);
        setIsEditing(false);
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this vector store?")) {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/delete-vector-store/${selectedVectorStoreId}`, {
                    method: 'DELETE'
                });
                if (!response.ok) throw new Error('Failed to delete vector store');
                const remainingStores = vectorStores.filter(store => store.vectorStoreId !== selectedVectorStoreId);
                setVectorStores(remainingStores);
                setSelectedVectorStoreId(remainingStores.length > 0 ? remainingStores[0].vectorStoreId : '');
                alert('Vector store deleted successfully.');
            } catch (error) {
                console.error('Error deleting vector store:', error);
                alert('Failed to delete the vector store.');
            }
        }
    };

    const handleEdit = () => {
        if (selectedVectorStore) {
            setEditValues({
                name: selectedVectorStore.name,
                description: selectedVectorStore.description,
            });
            setIsEditing(true);
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const handleSaveEdit = async () => {
        if (selectedVectorStore) {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/update-vector-store/${selectedVectorStore.vectorStoreId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...editValues,
                    })
                });
                const updatedStore = await response.json();
                setVectorStores(vectorStores.map(store => store.vectorStoreId === updatedStore.vectorStoreId ? updatedStore : store));
                setIsEditing(false);
            } catch (error) {
                console.error('Error updating vector store:', error);
                alert('Failed to update the vector store.');
            }
        }
    };

    const handleChange = (e) => {
        setEditValues({
            ...editValues,
            [e.target.name]: e.target.value
        });
    };

    const handleFileChange = (event) => {
        const newFiles = [...event.target.files];
        setFilesToUpload([...filesToUpload, ...newFiles]);
        event.target.value = '';
    };

    const handleRemoveFile = async (fileId) => {
        if (window.confirm("Are you sure you want to delete this file?")) {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/delete-file/${fileId}`, {
                    method: 'DELETE'
                });
                if (response.ok) {
                    setFiles(files.filter(file => file.fileId !== fileId));
                    alert('File deleted successfully.');
                } else {
                    throw new Error('Failed to delete file');
                }
            } catch (error) {
                console.error('Error deleting file:', error);
                alert('Failed to delete the file.');
            }
        }
    };

    const handleUploadFiles = async () => {
        const formData = new FormData();
        filesToUpload.forEach(file => formData.append('files', file));

        try {
            setIsLoading(true);
            const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/upload-files/${selectedVectorStoreId}`, {
                method: 'POST',
                body: formData,
            });
            if (response.ok) {
                const newFilesResponse = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/files/${selectedVectorStoreId}`);
                const newFiles = await newFilesResponse.json();
                setFiles(newFiles);
                setFilesToUpload([]);
                setIsLoading(false);
                alert('Files uploaded successfully.');
            } else {
                throw new Error('Failed to upload files');
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            alert('Failed to upload files.');
            setIsLoading(false);
        }
    };

    return (
        <div className='vector-stores'>
            <h1 className='admin-title'>Knowledge Bases Settings</h1>
            <select value={selectedVectorStoreId} onChange={handleSelectionChange} className="form-control">
                {vectorStores.map(store => (
                    <option key={store.vectorStoreId} value={store.vectorStoreId}>
                        {store.name || "Unnamed Store"}
                    </option>
                ))}
            </select>
            {selectedVectorStore && (
                <div>
                    <button onClick={handleDelete} className="danger-btn">Delete</button>
                    {!isEditing && <button onClick={handleEdit} className="edit-btn">Edit</button>}
                    <br></br>
                    <h2>Configuration</h2>
                    {isEditing ? (
                        <>
                            <div>
                                <label><strong>Name:</strong></label>
                                <input type="text" name="name" value={editValues.name} onChange={handleChange} className="form-control" />
                            </div>
                            <div>
                                <label><strong>Description:</strong></label>
                                <textarea name="description" value={editValues.description} onChange={handleChange} className="form-control"></textarea>
                            </div>
                            <button onClick={handleSaveEdit} className="save-btn">Save changes</button>
                            <button onClick={handleCancelEdit} className="cancel-btn">Cancel</button>
                        </>
                    ) : (
                        <>
                            <p><strong>Name:</strong> <b>{selectedVectorStore.name || "Unnamed Store"}</b></p>
                            <p><strong>Created at:</strong> {new Date(selectedVectorStore.createdAt * 1000).toLocaleString()}</p>
                            <p><strong>VectorStoreId:</strong> {selectedVectorStore.vectorStoreId}</p>
                            <p><strong>Description:</strong> {selectedVectorStore.description}</p>
                            <p><strong>Files:</strong></p>
                            <p><strong><label htmlFor="files"></label></strong></p>
                            <input
                                type="file"
                                id="files"
                                multiple
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                            />
                            <div className="custom-file-input">
                                <button type="button" className="edit-btn" onClick={() => fileInputRef.current.click()}>Add files</button>
                                <span>{filesToUpload.length > 0 ? `${filesToUpload.length} file(s) selected` : 'No file chosen'}</span>
                                {filesToUpload.length > 0 && (
                                    <button type="button" onClick={handleUploadFiles} className="edit-btn" disabled={isLoading}>
                                        {isLoading ? <span className="spinner"></span> : 'Upload'}
                                    </button>
                                )}
                            </div>
                            <ul>
                                {files.map(file => (
                                    <li key={file.fileId}>
                                        {file.fileName}
                                        <button onClick={() => handleRemoveFile(file.fileId)} className='remove-btn'>x</button>
                                        <p></p>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default VectorStores;