import React, { useEffect, useRef } from 'react';

const ChatInput = ({ sideMenuVisible, input, setInput, handleSubmit, isLoading }) => {
    const textareaRef = useRef(null);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(event);
        }
    };

    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = 'auto'; // Reset height
        textarea.style.height = `${Math.min(textarea.scrollHeight, 140)}px`; // Set height based on content
    }, [input]);

    return (
        <div className={`chat-input-holder ${sideMenuVisible ? 'with-side-menu' : ''}`}>
            <form onSubmit={handleSubmit} className="chat-input-form">
                <textarea
                    ref={textareaRef}
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className={`chat-input-textarea ${isLoading ? 'disabled' : ''}`}
                    placeholder="Message Chat-BNB..."
                    disabled={isLoading} // Disable the input when loading
                    rows={1} // Default rows
                    style={{ resize: 'none', overflowY: 'auto' }} // Ensure textarea doesn't resize manually
                />
                {!isLoading && (
                    <button type="submit" id="send-button" className="submit-button">
                        Send
                    </button>
                )}
                {isLoading && <div className="spinner"></div>}
            </form>
        </div>
    );
};

export default ChatInput;