import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserProvider } from './components/userContext';

// Create a root.
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render your app within GoogleOAuthProvider, BrowserRouter and StrictMode.
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="563104687257-56ml4uf8htddcj8k4kidurkpm0dbapfj.apps.googleusercontent.com">
      <Router> {/* Wrap the App component with Router */}
      <UserProvider>
        <App />
        </UserProvider>
      </Router>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();