import React, { useState, useEffect } from 'react';
import { useUser } from './userContext';
import Select from 'react-select';

const SecurityGroups = () => {
    const [groups, setGroups] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [editValues, setEditValues] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [userOptions, setUserOptions] = useState([]);
    const [specificUserEmails, setSpecificUserEmails] = useState([]);
    const { userId } = useUser();
    const [assistantOptions, setAssistantOptions] = useState([]); // Updated: Assistant options for Select
    const [assistantNames, setAssistantNames] = useState([]); // Assistant names to display
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Fetch all security groups from the API
        fetch('/securitygroups')
            .then(response => response.json())
            .then(data => setGroups(data))
            .catch(error => console.error('Failed to fetch groups:', error));
    }, []);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/users`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    setUserOptions(data.map(user => ({ value: user._id, label: `${user.name} (${user.email})` })));
                } else {
                    console.error('Expected an array of users but got:', data);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        }

        fetchUsers();
    }, []);

    useEffect(() => {
        if (!userId) return;
        async function fetchAssistants() {
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/assistants?userId=${userId}`);
                const data = await response.json();
                if (Array.isArray(data)) {
                    setAssistantOptions(data.map(assistant => ({ value: assistant._id, label: assistant.name })));
                } else {
                    console.error('Expected an array of assistants but got:', data);
                }
            } catch (error) {
                console.error('Error fetching assistants:', error);
            }
        }

        fetchAssistants();
    }, []);

    useEffect(() => {
        const fetchUserEmails = async (userIds) => {
            if (userIds.length === 0) return;
            try {
                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/users/details`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userIds })
                });
                const data = await response.json();
                if (Array.isArray(data)) {
                    setSpecificUserEmails(data.map(user => user.email));
                } else {
                    console.error('Expected an array of user details but got:', data);
                }
            } catch (error) {
                console.error('Error fetching user emails:', error);
            }
        };

        if (selectedGroupId) {
            const selectedGroup = groups.find(group => group._id === selectedGroupId);
            if (selectedGroup && selectedGroup.users && selectedGroup.users.length > 0) {
                fetchUserEmails(selectedGroup.users);
            } else {
                setSpecificUserEmails([]);
            }

            if (selectedGroup && selectedGroup.assistants && selectedGroup.assistants.length > 0) {
                const assistantNames = selectedGroup.assistants.map(assistantId => {
                    const assistant = assistantOptions.find(option => option.value === assistantId);
                    return assistant ? assistant.label : assistantId; // Display name or fallback to ID
                });
                setAssistantNames(assistantNames);
            } else {
                setAssistantNames([]);
            }
        }
    }, [selectedGroupId, groups, assistantOptions]); // Added `assistantOptions` dependency

    const selectedGroup = groups.find(group => group._id === selectedGroupId);

    const handleSelectionChange = (event) => {
        setSelectedGroupId(event.target.value);
        setIsEditing(false);
    };

    const handleEdit = () => {
        if (selectedGroup) {
            setEditValues({
                name: selectedGroup.name || '',
                domains: selectedGroup.domains ? selectedGroup.domains.join(', ') : '',
                users: selectedGroup.users ? selectedGroup.users.map(userId => {
                    const user = userOptions.find(option => option.value === userId);
                    return user ? user : { value: userId, label: userId };
                }) : [],
                assistants: selectedGroup.assistants ? selectedGroup.assistants.map(assistantId => {
                    const assistant = assistantOptions.find(option => option.value === assistantId);
                    return assistant ? assistant : { value: assistantId, label: assistantId };
                }) : []
            });
            setIsEditing(true);
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
    };

    const handleSaveEdit = async () => {
        if (selectedGroupId) {
            try {
                setIsLoading(true);

                const response = await fetch(`${process.env.REACT_APP_CLIENT_HOME_URL}/update-security-group/${selectedGroupId}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...editValues,
                        users: editValues.users.map(user => user.value),
                        assistants: editValues.assistants.map(assistant => assistant.value),
                        domains: editValues.domains.split(',').map(domain => domain.trim()),
                    })
                });

                const updatedGroup = await response.json();

                if (response.ok) {
                    setGroups(groups.map(group => group._id === updatedGroup._id ? updatedGroup : group));
                    setIsEditing(false);
                } else {
                    console.error('Failed to update group:', updatedGroup);
                    alert('Failed to update the security group.');
                }

                setIsLoading(false);
            } catch (error) {
                console.error('Error updating security group:', error);
                alert('Failed to update the security group.');
                setIsLoading(false);
            }
        }
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this security group?")) {
            try {
                const response = await fetch(`/securitygroups/${selectedGroupId}`, {
                    method: 'DELETE'
                });
                const data = await response.json();
                setGroups(groups.filter(group => group._id !== selectedGroupId));
                alert('Security group deleted successfully.');
            } catch (error) {
                console.error('Error deleting group:', error);
                alert('Failed to delete the security group.');
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleUsersChange = (selectedOptions) => {
        setEditValues({
            ...editValues,
            users: selectedOptions
        });
    };

    const handleAssistantsChange = (selectedOptions) => {
        setEditValues({
            ...editValues,
            assistants: selectedOptions
        });
    };

    return (
        <div className='security-groups'>
            <h1 className='admin-title'>Security Groups Settings</h1>
            <select value={selectedGroupId} onChange={handleSelectionChange} className="form-control">
                <option value="" disabled>Select a group</option>
                {groups.map(group => (
                    <option key={group._id} value={group._id}>
                        {group.name}
                    </option>
                ))}
            </select>
            {selectedGroup && (
                <div>
                    <button onClick={handleDelete} className="danger-btn">Delete</button>
                    {!isEditing && <button onClick={handleEdit} className="edit-btn">Edit</button>}
                    <br></br>
                    <h2>Configuration</h2>
                    {isEditing ? (
                        <>
                            <div>
                                <label><strong>Name:</strong></label>
                                <input type="text" name="name" value={editValues.name} onChange={handleChange} className="form-control" />
                            </div>
                            <div>
                                <label><strong>Domains:</strong></label>
                                <input type="text" name="domains" value={editValues.domains} onChange={handleChange} placeholder="Enter domains, separated by commas" className="form-control" />
                            </div>
                            <div>
                                <label htmlFor="users"><strong>Users:</strong></label>
                                <Select
                                    id="users"
                                    isMulti
                                    value={editValues.users}
                                    onChange={handleUsersChange}
                                    options={userOptions}
                                    className="form-control-specific"
                                    placeholder='Select specific users'
                                />
                            </div>
                            <div>
                                <label htmlFor="assistants"><strong>Assistants:</strong></label>
                                <Select
                                    id="assistants"
                                    isMulti
                                    value={editValues.assistants}
                                    onChange={handleAssistantsChange}
                                    options={assistantOptions}
                                    className="form-control-specific"
                                    placeholder='Select specific assistants'
                                />
                            </div>
                            <button onClick={handleSaveEdit} className="save-btn" disabled={isLoading}>
                                {isLoading ? <span className="spinner"></span> : 'Save changes'}
                            </button>
                            <button onClick={handleCancelEdit} className="cancel-btn">Cancel</button>
                        </>
                    ) : (
                        <>
                            <p><strong>Name:</strong> <b>{selectedGroup.name}</b></p>
                            <div>
                                <p><strong>Domains:</strong></p>
                                <div className="domain-list-box">
                                    {selectedGroup.domains && selectedGroup.domains.length > 0 ? (
                                        selectedGroup.domains.map((domain, index) => (
                                            <div key={index} className="domain-chip">
                                                {domain}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No domains available.</p>
                                    )}
                                </div>
                            </div>
                            <div>
                                <p><strong>Users:</strong></p>
                                <div className="user-list-box">
                                    {specificUserEmails.length > 0 ? (
                                        specificUserEmails.map((email, index) => (
                                            <div key={index} className="user-chip">
                                                {email}
                                            </div>
                                        ))
                                    ) : (
                                        <p>No users available.</p>
                                    )}
                                </div>
                            </div>
                            <div>
                                <p><strong>Assistants:</strong></p>
                                <div className="assistant-list-box">

                                    
                                    {assistantNames.length > 0 ? (
                                        assistantNames.map((name, index) => (
                                            <li key={index} className="assistant-chip">
                                                {name}
                                            </li>
                                        ))
                                    ) : (
                                        <p>No assistants available.</p>
                                    )}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default SecurityGroups;